import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from '../components/Layout';
import { useLocation } from '@reach/router';

const MarkdownPage = ({ data }) => {
  const { html } = data.markdownRemark;
  const { title } = data.markdownRemark.frontmatter;

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const sab = JSON.parse(params.get('sab') ?? true); // Show App Bar
  console.log(sab);
  return (
    <Layout>
      {sab && <Header pageOffset={1} alwaysVisible />}
      <div className="container">
        <h1 className={sab ? 'navbar-height-margin' : 'mt-5'}>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <Footer />
    </Layout>
  );
};

export default MarkdownPage;

export const query = graphql`
  query LegalsPageDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        seo
      }
    }
  }
`;
